import React from 'react';
import '../css/AboutMe.css';
import ScrollAnimation from 'react-animate-on-scroll';

const AboutMe = React.forwardRef((props, ref)=>{

    return (
        <div>
        <div className='box2 color' ref={ref}>
            <div className='extraSpace'></div>
            <div className='content'>
                <div className='aboutMe'>About Me</div>
                <div className='whiteSpace'></div>
            </div>
            <div className='content'>
                <div className='text'>
                    <ScrollAnimation animateIn="flipInY">
                        I am Chianne Connelly. I graduated from Georgia 
                        Tech in 2019 and began my career as a software 
                        engineer. Check out this video to learn more!
                    </ScrollAnimation>
                </div>
                <div className="wrapper">
                    <object className="vid" data="https://www.youtube.com/embed/Nm_f_44ZgLM"></object>
                </div>
            </div>
        </div> 
        <div className='extraSpace'></div>
        </div>
    )
}) 

export default AboutMe;