import React from 'react';
import '../css/Project.css';
import ScrollToTopOnMount from './ScrollToTopOnMount';


function Web() {
    return(
      <div>
        <ScrollToTopOnMount />
        <div class="projPic headerColor">
          <h4 class='projHeader'>This Website</h4>
        </div>
        <div class="projBg color">
          <div class="projContent">
            <h4 class='sectionHeader'>Overview</h4>
            <p class='feats'>It's like inception but in a website.
            </p>
            <br/>
            <br/>
            <div className="h_iframe">
              <object data="./index.html" width="800" height="600"></object>
            </div>
            <h4 class='sectionHeader'>Features/Tech Used</h4>
            <p class='feats'>This website was built from scratch in <div className='emphasize'>React</div> from Dec 2019 - Jan 2020 using <div className='emphasize'>HTML</div>, <div className='emphasize'>CSS</div>, and <div className='emphasize'>JavaScript</div>. 
            All widgets and views were custom created and the website was designed to work in dark and light modes across platforms.
            <div className='emphasize'> React Router</div> was implemented to increase functionality and usability. The source code can be found <a href="https://github.com/cconnelly7/personal_website" target="_blank">here</a>. 
            </p>
            <div class='extraSpace'></div>
          </div>
        </div>
      </div>
    )
}

export default Web;